<template>
  <div class="bg-fff">
    <div class="login-nei-rong">
      <div class="login-body">
        <nav-header fixed title="手机验证码登入"/>
        <user-login-top/>
        <div class="body-margin input-css">
          <van-form @submit="onSubmit">
            <van-field
                v-model="form.mobile"
                placeholder="请输入手机号"
            />
            <van-field
                v-model="form.code"
                maxlength="6"
                placeholder="请输入验证码"
            >
              <template #button>
                <a href="javascript:" @click="getCode(form.mobile)">{{ getCodeTitle }}</a>
              </template>
            </van-field>
            <div class="margin-top-40">
              <van-button round block type="primary" native-type="submit">
                提交
              </van-button>
            </div>
          </van-form>
          <van-row class="ti-shi">
            <van-col :span="12">
              <a href="javascript:" @click="$router.push({name:'NumberLogin'})">账号密码登入</a>
            </van-col>
            <van-col :span="12" class="text-right">
              <a href="javascript:" @click="$router.push({name:'EditMobile'})">修改手机号</a>
            </van-col>
          </van-row>
        </div>
      </div>
      <div class="login-xie-yi">
        <xie-yi v-model:val="form.xie_yi"/>
      </div>
    </div>
  </div>
</template>

<script>
//账号密码登入
import YanZhengMa from "../open/yanZhengMa";
import NavHeader from "../../components/top/NavHeader";

export default {
  name: 'NumberLogin',
  components: {NavHeader},
  mixins: [YanZhengMa]
}
</script>
<script setup>
import UserLoginTop from "./public/UserLoginTop";
import {ref} from 'vue'
import {useRouter} from 'vue-router'
import http from "../../api/http";
import {getReturnUrl, setUserInfo} from '@/api/cookie'
import XieYi from "./public/XieYi";
import {TongYiXieYi} from "@/api/AlertMessage";

const router = useRouter()
let form = ref({})

function onSubmit() {
  if (!form.value.xie_yi) {
    TongYiXieYi().then(()=>{
      form.value.xie_yi = true
      dengRu()
    })
  }else {
    dengRu()
  }


}
function dengRu(){
  http.post('/gzhphp/login/mobileLogin', form.value).then(da => {
    if (da.code === 1) {
      setUserInfo(da.data)
      router.push(getReturnUrl())
    }
  })
}
</script>

<style scoped lang="less">
@import "../../assets/login";

</style>
